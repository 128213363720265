import React from "react"

import footerStyles from "./Footer.module.css"
import link from "../images/link.svg"
import leetcode from "../images/leetcode.svg"
import chat from "../images/chat.svg"

export default () => {
  return (
    <div className={footerStyles.container}>
      <div className={footerStyles.moreContainer}>
        <h2>更多产品</h2>
        <div className={footerStyles.row}>
          <img alt="" src={chat} className={footerStyles.icon} />
          <a
            rel="noopener noreferrer"
            href="https://mdnice.com/"
            target="_blank"
          >
            微信公众号排版工具
          </a>
        </div>
        <div className={footerStyles.row}>
          <img alt="" src={link} className={footerStyles.icon} />
          <a
            rel="noopener noreferrer"
            href="https://urlify.cn/"
            target="_blank"
          >
            短链接生成
          </a>
        </div>
        <div className={footerStyles.row}>
          <img alt="" src={leetcode} className={footerStyles.icon} />
          <a
            rel="noopener noreferrer"
            href="https://draw.mdnice.com/algorithm/"
            target="_blank"
          >
            力扣刷题
          </a>
        </div>
      </div>
      <div className={footerStyles.noticeContainer}>
        <h2>注意</h2>
        <p>请勿上传违反中国大陆和香港法律的图片，违者后果自负。</p>
        <p>
          Copyright Ⓒ 2019-2020 图壳 -{" "}
          <a
            rel="noopener noreferrer"
            href="http://beian.miit.gov.cn/"
            target="_blank"
          >
            浙ICP备18020649号
          </a>
          . All rights reserved.
        </p>
      </div>
    </div>
  )
}
