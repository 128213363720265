import React, { useState } from "react"
import { Menu, Dropdown, message, Avatar } from "antd"
import navbarStyles from "./Navbar.module.css"
import logo from "../images/imgkr_logo.png"
import { LOGIN_URL, USER_API, USER_LOGOUT_API } from "../utils/constant"
import { axiosBackEnd } from "../utils/helper"

export default () => {
  const [username, setUsername] = useState("")
  const [headimgurl, setHeadimgurl] = useState("")


  const signIn = () => {
    axiosBackEnd
      .get(USER_API)
      .then(res => {
        if(!!res && !!res.data && !!res.data.data){
          setUsername(res.data.data.nickname);
          setHeadimgurl(res.data.data.headimgurl);
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  const picManage = () => {
    message.warn("暂缓开放，敬请期待！！！")
  }

  const signOut = () => {
    axiosBackEnd
      .get(USER_LOGOUT_API)
      .then(res => {
        message.success("退出登录成功")
        setTimeout(() => {
          window.location.href = "/"
        }, 1000);
      })
      .catch(err => {
        console.error(err)
      })
  }


  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a
          className="nice-user-link"
          rel="noopener noreferrer"
          onClick={picManage}
          href="/#"
        >
          图片管理
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <a
          className="nice-user-link"
          rel="noopener noreferrer"
          onClick={signOut}
          href="/#"
        >
          退出登录
        </a>
      </Menu.Item>
    </Menu>
  )
  signIn();
  return (
    <header className={navbarStyles.navbar}>
      <div className={navbarStyles.navbarItem}>
        <img alt="" src={logo} className={navbarStyles.logo} />
        <section className={navbarStyles.rightNav}>
          <a href="/#home">回家</a>
          <a href="/#upload">上传</a>
          <a href="/#about">关于</a>
          <a href="/pricing">费用</a>
          {username ? <Dropdown overlay={menu}>
            <a
              className="ant-dropdown-link nice-user-link"
              rel="noopener noreferrer">
              {/* <img src={headimgurl} alt="" /> */}
              <Avatar src={headimgurl} />
            </a>
          </Dropdown> : <a href={LOGIN_URL}>登录</a>}
        </section>
      </div>
    </header>
  )
}
